import { TreeProps, RoleTableProps } from '@/views/User/types'
import { AutoTableCellProps, APIDataProps, RowCellProps, CategoryProps } from '@/types'
import { ColmunsProps } from '@/API/types'
import { useRoute } from 'vue-router'
import store from '@/store'
import deepClone from '@/utils/deepClone'
import { ref, onMounted, onUnmounted } from 'vue'
import type { BuTreeProps } from '@/store/'

export const addDataKey = (data: any) => {
    data.forEach((item: any, index: number) => {
        item.key = index + 1
    })
    return data
}
/** findParent
 * @param {}[] arr 目标数据
 * @param string mnuuri 传入的，路由url
 * @param callFied 匹配mnuuri的目标数据Key
 * @param callbackType 返回数据类型
 * @param callbackKey 返回权限数据的来源字段
*/
export const findParent = (arr: TreeProps[], mnuuri: string, callFied: string, callbackType: string, callbackKey: string): any => {
    let callType;
    switch (callbackType) {
        case 'Array':
            callType = [];
            break;
        case 'Object':
            callType = {};
            break;
        default:
            callType = '';

    }
    if (arr === []) return callType
    for (const obj of arr) {
        if (obj[callFied] === mnuuri) {
            return obj[callbackKey] ? obj[callbackKey] : callType
        }
        const ret = findParent(obj.children, mnuuri, callFied, callbackType, callbackKey)
        if (ret.length > 0) return ret
    }
    return callType;
}

/** 根据路由，获取权限信息
 * @param path 路由
*/
export const getPermissions = (path = ''): RoleTableProps[] => {
    const route = useRoute()
    path = !path ? route.path : path
    if (Object.keys(store.state.user).length > 0) {
      return findParent(store.state.user.menulist, path, 'mnuuri', 'Array', 'privlist')
    }
    return []
}
/** @param privcd string 传过来的权限矩阵表里，设置的对应按钮的privcd
 * @param path string 路由
 */
export const btnPermissions = (privcd: string, path = '') => {
    /* todo
    zhangyan 定的：
    0。权限不涉及到的按钮，不控制,默认显示
    1。权限里面需要控制的按钮：
    （1）后端返回的privlist找不到privcd，显示
    （2）后端返回的privlist找到了privcd，并且state: true的时候显示
    return !(getPermissions().filter(item => item.privcd === privcd).length < 0) || (getPermissions().filter(item => item.privcd === privcd && item.state)).length > 0
    todo */
    // 目前先按之前的做，等后端收集全，再改成上面的
    return (getPermissions(path).filter(item => item.privcd === privcd)).length > 0
}


// 字符串转换布尔值
export const stringToBoolean = (str: string) => {
    switch (str.toLowerCase().trim()) {
        case "true": case "yes": case "1": return true;
        case "false": case "no": case "0": case null: return false;
        default: return Boolean(str);
    }
}

// 递归一棵树
export const fnChildren = (data: any, id = '0', link = 'parent') => 
    data.filter((item: any) => item[link] === id)
    .map((item: any) => ({...item, children: fnChildren(data, item.id)}))


export const formatData = (data: APIDataProps[]) => {
    data.forEach((item: APIDataProps) => {
        if (item.tableCell && item.tableCell.length > 0) {
            for (const key of item.tableCell) {
                for (const i in key) {
                    if (key[i].constructor === Object) {
                        (key[i] as RowCellProps).isEdit = true;
                        (key[i] as RowCellProps).isAuto = false
                        if ((key[i].val as string[]).length > 0) {
                            for (const s of key.children as AutoTableCellProps[]) {
                                (s[i] as RowCellProps).isEdit = false;
                                (s[i] as RowCellProps).isAuto = false
                            }
                        } else {
                            for (const s of key.children as AutoTableCellProps[]) {
                                (s[i] as RowCellProps).isEdit = true;
                                (s[i] as RowCellProps).isAuto = false
                            }
                        }
                    }
                }
            }
        }
    })
}

// 找到category, base info category用
export const getCategory = (): void => {
    const arr = store.state.pgmData.businessUnit.option.filter((item: CategoryProps) => item.id === store.state.pgmData.businessUnit.selected)
    if (arr.length > 0) {
        store.commit('updatePgmGroupCategorys', arr[0].category)
    }
}

// 格式化program的base info的分类信息,用于下面的大分类显示
export const formatPgmCategory = (): void => {
    let arr: CategoryProps[] = []
    const sourceData = deepClone(store.state.pgmGroupCategorys)
    for (const item of sourceData) {
        arr = arr.concat(item.offerType)
    }
    store.commit('updatePgmCategory', arr)
}

//  保存FA的参数
export const formatFaSaveParams = (finacingParams: any, flParams: any, agilityParams: any, updateParams: any) => {
    const params = {
        finacingParams,
        flParams,
        agilityParams,
        updateParams,
    }
    
    store.commit('updatedFaSave', params)
}

// item不传时，在新建vehicle组时，下面的pre需要更新用
// 传item时，是直接更新pre的vehicle组
export const getGroup = () => {
    const arr: { id: string; display: string; selected: boolean }[] = []
    for (const i of store.state.pgmData.eligibility.vehicle) {
        const param = {
            id: i.groupId,
            display: i.groupName,
            selected: false
        }
        arr.push(param)
    }
    return arr
}


// 处理向后端传的参数，后端不希望把tableCellSelect的值给它，所以要清空
export const formatRequestData = () => {
    const param = deepClone(store.state.pgmData)
    // delete param.select
    param.eligibility.dealer.tableCellSelect = []
    for (const item of param.eligibility.vehicle) {
        item.tableCellSelect = []
    }
    return param
}

// 转换数字 例如 1 =》 01
export const formatInt = (number: number, len: number): string => {
    let mask = ''
    let returnVal = ''
    for (let i = 0; i < len; i++) mask += "0";
    returnVal = mask + number; 
    returnVal = returnVal.substr(returnVal.length - len, len);
    return returnVal;
}

// 生成UUID
export const guid = (): string => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    })
}

// Model Year带Pre的处理
export const handleChangePre = (col: string,  row: AutoTableCellProps) => {
    // 当最后选到Pre时，要把前面非pre都清掉
    if (row[col].val[row[col].val.length - 1] && row[col].val[row[col].val.length - 1].indexOf('Pre') !== -1 ) {
        row[col].val = [row[col].val[row[col].val.length - 1]]
    } else {
        // 当前面已经存在pre，再想选别的，那么就清掉带pre的
        const index = row[col].val.findIndex((item: string) => item.indexOf('Pre') !== -1)
        if (row[col].val.findIndex((item: string) => item.indexOf('Pre') !== -1) !== -1) {
            row[col].val.splice(index, 1)
        }
    }
}

// 根据title 查找对应的id 用于动态表头
export const findColumnIdBytitle = (title: string, columns: ColmunsProps[] = []): string => {
    const childrenArr: any[] = []
    const colArr: any[] = []
    let dataId = '' 
    columns.forEach((col) => {
        for (const key in col) {
            if (key === 'children') {
                childrenArr.push(col.children)
            }
            if (Object.prototype.hasOwnProperty.call(col, 'children')) {
                colArr.push(col)
            }
            
            // if (!col.hasOwnProperty('children')) {
            //     colArr.push(col)
            // }
        }
    })  
    // if (colArr.length > 0 && colArr[0].title) dataId = colArr.filter(item => title === item.title)[0].dataIndex
    if (childrenArr.flat().length > 0 && childrenArr.flat().filter(item => title === item.title).length > 0) dataId = childrenArr.flat().filter(item => title === item.title)[0].dataIndex
    return dataId
}

// 表格的行的勾选框是否可勾
export const getCheckboxProps = (record: AutoTableCellProps) => {
    return record.parent !== '0' ? { disabled: true } : { disabled: false }
}

export const elementSize = (element: string | HTMLElement) => {
  let elementDom: HTMLElement | null = null
  const size = ref({
    width: 0,
    height: 0
  })
  const getSize = () => {
    if (elementDom) {
      size.value.width = elementDom.offsetWidth
      size.value.height = elementDom.offsetHeight
    }
  }
  onMounted(() => {
    elementDom = typeof element === 'string' ? document.querySelector(element) : element
    getSize()
  })
  
  window.addEventListener('resize', getSize)

  onUnmounted(() => {
    window.removeEventListener('resize', getSize)
  })

  return size
}

export function isMobile() {
  return /Android|webOS|iPhone|iPod|BlackBerry|mobile/i.test(navigator.userAgent)
}

/**
 * 根据指定参数名称获取参数值
 * @param {String} url    参数名称
 * @return  {Object} 参数值
 * */
export function getUrlParams(url: string | undefined = location.href): any {
  return Object.fromEntries(new URL(url).searchParams.entries())
}

export interface ProgramConfigProps {
    programId: string;
    programName: string;
    programCode: string;
    programPeriod: APIDataProps;
    programOfferType: () => CategoryProps[];
}

export const programConfig = (): ProgramConfigProps => {
    const param = {
        programId: store.state.pgmData.programId,
        programName: store.state.pgmData.programName,
        programCode: store.state.pgmData.programCode,
        programPeriod: store.state.pgmData.eligibility.period,
        programOfferType: () => store.state.pgmCategory.filter((item: CategoryProps) => item.selected)
    }
    return param
}

export function replaceUrl(url: string | undefined = location.href) {
    url = url.split('?')[0];
    if (url.indexOf('?') !== -1) {
        window.history.replaceState(null, '', url);
    }
}

// 获取月
export function getMonth(date: Date = new Date()): number {
    return date.getMonth()
}

/**
 * 获取一级BU
 * @param buTree BU Tree
 * @param bu BU英文名
 * @returns 
 */
function _getFirstLevelBU(buTree: BuTreeProps[], bu: string) {
    for (const buItem of buTree) {
        if (buItem.nameEn === bu || _getFirstLevelBU(buItem.children, bu)) {
            return buItem
        }
    }
    return null;
}

/**
 * 获取一级BU
 */
export function getFirstLevelBU() {
    return _getFirstLevelBU(store.state.user.buTreeList ?? [], store.state.user.bu?.orgnmen ?? '')
}