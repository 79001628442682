
import { defineComponent, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { MaintainCellProps, MaintainColProps, MaintainChildren } from './types';
import { ColmunsProps, ColmunsChildrenProps } from '@/API/types';
import { getCalculatorOperation, updateState, deleteCalculator } from '@/API/finance';
import { message } from 'ant-design-vue';
import { BuItem } from '@/views/Approve/SalesRoleApprove/ProgramHome/types';
import useTableHeight from '@/hooks/useTableHeight';
import { findColumnIdBytitle } from '@/utils';
import { useStore } from 'vuex';
import { cloneDeep } from 'lodash';

interface StatusParams {
  typeClassCode: string;
  modelYear: string;
  model: string;
  nstGroupName: string;
}

interface OperationParams {
  status: string;
  brands: string[];
  sheetType: string;
  selectBrandId: string;
}

const breadCrumbNames = {
  unconfirm: 'Confirm Calculator Data',
  confirm: 'Re-open Calculator to Upload New Data',
  delete: 'Delete Calculator Data'
};

const buttonNames = {
  unconfirm: 'Confirm',
  confirm: 'Re-open',
  delete: 'Delete'
};

const modalContents = {
  unconfirm: 'confirm',
  confirm: 're-open',
  delete: 'delete'
};

export default defineComponent({
  name: 'Calculator Confirm',
  setup() {
    const store = useStore();
    const route = useRoute();
    const { tableHeight } = useTableHeight(false);
    const currentStatus = store.state.finance.stateParmas.status;
    const breadCrumbName = breadCrumbNames[currentStatus];
    const buttonName = buttonNames[currentStatus];
    const modelContent = modalContents[currentStatus];
    // 当选中项发生变化
    const selectedKeys = ref();
    // 配置选择框
    const rowSelection = {
      onChange: (selectedRowKeys: []) => {
        selectedKeys.value = selectedRowKeys;
      }
      // hideDefaultSelections: true,
    };

    // 获取children中的数据，进行配置
    const deployChild = (arr: MaintainChildren[], type?: string): ColmunsChildrenProps[] => {
      const children: ColmunsChildrenProps[] = [];
      arr.forEach((item: MaintainChildren) => {
        if (type === 'fixed') {
          if (item.display === 'Model') {
            children.push({
              title: item.display,
              dataIndex: item.id,
              key: item.id,
              width: route.params.brand !== 'MB' ? 230 : 150,
              align: 'center'
            });
          } else {
            children.push({
              title: item.display,
              dataIndex: item.id,
              key: item.id,
              width: 130,
              align: 'center'
            });
          }
        } else {
          children.push({
            title: item.display,
            dataIndex: item.id,
            key: item.id,
            slots: { customRender: item.id },
            width: 240,
            align: 'center'
          });
        }
      });
      return children;
    };

    let tableColConfig: any[] = []
    const columns = ref<ColmunsProps[]>([]);
    const configColumns = (data: MaintainColProps[]) => {
      columns.value = [];
      data.forEach((item: MaintainColProps, index: number) => {
        //判断是否是第一个 最后一个，并是否有中间滚动的部分
        if (index === 0 && data.length > 3) {
          columns.value.push({
            title: item.display,
            fixed: 'left',
            children: deployChild(item.children, 'fixed')
          });
        } else if (index === data.length - 1 && data.length > 3) {
          if (currentStatus === 'delete') {
            columns.value.push({
              title: item.display,
              children: deployChild(item.children)
            });
          } else {
            columns.value.push({
              title: item.display,
              fixed: 'right',
              children: deployChild(item.children, 'fixed')
            });
          }
        } else if (index === 0) {
          columns.value.push({
            title: item.display,
            children: deployChild(item.children, 'fixed')
          });
        } else if (index === data.length - 1) {
          columns.value.push({
            title: item.display,
            children: currentStatus === 'delete' ? deployChild(item.children) : deployChild(item.children, 'fixed')
          });
        } else {
          columns.value.push({
            title: item.display,
            children: deployChild(item.children),
            align: 'center'
          });
        }
        if (data.length === 2) {
          columns.value.splice(1, 0, {
            title: '',
            dataIndex: ''
          });
        }
      });
    };

    const findColIdByDisplay = (display: string, tableColConfig: any[]): string | null => {
      for (const col of tableColConfig) {
        if (col.display === display) {
          return col.id as string
        }
        if (col.children) {
          const colId: string | null = findColIdByDisplay(display, col.children)
          if (colId) {
            return colId
          }
        }
      }
      return null
    }

    // 获取表格的数据
    const colArr = ref<string[]>([]);
    const dataSource = ref();
    const getTableData = () => {
      const params = store.state.finance.stateParmas;
      getCalculatorOperation({ params }).then((res) => {
        tableColConfig = cloneDeep(res.maintain.tableCol)
        const tableCol = res.maintain.tableCol;
        tableCol.forEach((colLevel1: any) => {
          if (colLevel1.display === 'Program Period' || colLevel1.display === 'Program') {
            const children = colLevel1.children;
            const newChildren = children.filter((child: any) => child.display !== 'Program ID');
            colLevel1.children = newChildren;
          }
        });
        //根据id排序
        tableCol.sort((x: any, y: any) => {
          return x.id - y.id;
        });
        if (store.state.finance.stateParmas.brand !== 'MB') {
          dataSource.value = res.maintain.tableCell.filter((item) => item.id !== item.parentId);
        } else {
          dataSource.value = res.maintain.tableCell.filter((item) => item.id === item.parentId);
        }
        if (Array.isArray(dataSource.value) && dataSource.value.length > 0)
          colArr.value = Object.keys(dataSource.value[0]);
        // 配置columns
        configColumns(tableCol);
      });
    };

    // 初始化数据
    const init = async () => {
      getTableData();
    };

    // 获取选中的参数
    const getParams = () => {
      const params: any[] = [];
      const arr = dataSource.value.filter((item: MaintainCellProps) => selectedKeys.value.includes(item.id));
      const programId = findColIdByDisplay('Program ID', tableColConfig)
      arr.forEach((item: MaintainCellProps) => {
        params.push({
          typeClassCode: item[findColumnIdBytitle('Type Class', columns.value)],
          modelYear: item[findColumnIdBytitle('Model Year', columns.value)],
          model: item[findColumnIdBytitle('Model', columns.value)],
          nstGroupName: item[findColumnIdBytitle('NST Group Name', columns.value)] || '',
          programId: programId ? item[programId] : undefined
        });
      });
      return params;
    };
    // 点击按钮确认更改状态
    const visibleTip = ref(false);
    const changeStatus = () => {
      visibleTip.value = true;
    };

    // 更改当前状态的时候
    const onChangeStatus = () => {
      const selectedData = getParams();
      if (selectedData.length === 0) {
        message.error('请选择至少一项数据');
        return;
      }
      const configParams = store.state.finance.stateParmas;
      const status = configParams.status;
      if (status === 'delete') {
        deleteCalculator(configParams, selectedData).then(() => {
          visibleTip.value = false;
          getTableData();
          selectedKeys.value = [];
        });
      } else {
        updateState(configParams, selectedData).then(() => {
          visibleTip.value = false;
          getTableData();
        });
      }
    };

    onMounted(() => {
      init();
    });
    return {
      tableHeight,
      columns,
      rowSelection,
      dataSource,

      changeStatus,
      visibleTip,
      onChangeStatus,
      colArr,
      selectedKeys,
      breadCrumbName,
      buttonName,
      modelContent
    };
  }
});
